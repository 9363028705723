



































































































































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import { EventBus } from '@/main';
import {OutputDeviceDTO, OutputLocationDTO, OutputOrganizationDTO, OutputRuleDTO, OutputRuleTriggerDTO} from "@/api";
import Loading from "@/components/Loading.vue";
import {formatApiTimeString} from "@/utils";
@Component({
  components: {Loading}
})
export default class AlarmView extends Vue {
  @Prop(Number) readonly alarmId!: number;

  public alarm: OutputRuleTriggerDTO|null = null;
  public device: OutputDeviceDTO|null = null;
  public location: OutputLocationDTO|null = null;
  public organization: OutputOrganizationDTO|null = null;
  public rule: OutputRuleDTO|null = null;

  public created() {
    EventBus.$on('alarm', (data) => {
      this.updateAlarm();
    });
  }

  public async mounted() {
    await this.updateAlarm();
  }

  public async updateAlarm() {
    this.alarm = await this.$store.dispatch('ruleTriggers/get', { ruleTriggerId: this.alarmId });

    [this.device, this.location, this.organization, this.rule] = await Promise.all([
        this.$store.dispatch('devices/get', { deviceId: this.alarm?.device_id }),
        this.$store.dispatch('locations/get', { locationId: this.alarm?.location_id }),
        this.$store.dispatch('organizations/get', { organizationId: this.alarm?.organization_id }),
        this.$store.dispatch('rules/get', { ruleId: this.alarm?.rule_id })
    ]);
  }

  // public async resolveAlarm() {
  //   if (this.alarm !== undefined) {
  //     this.alarm.is_active = false;
  //
  //     const alarm = await updateAlarm(this.$store, this.alarm.id!, this.alarm);
  //
  //     if (alarm) {
  //       this.alarm = alarm;
  //     }
  //   }
  // }

  get formattedAlarmTime() {
    return formatApiTimeString(this.alarm?.timestamp);
  }
}
